import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"
import PageNotFound from "../views/PageNotFound.vue"
import EventView from "../views/EventView.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/events/:slug/:id",
		name: "event",
		component: EventView,
	},
	{path: "*", component: PageNotFound},
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

export default router
