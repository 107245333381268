import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import DatetimePicker from "vuetify-datetime-picker"
import {api} from "./.env.js"
import axios from "axios"
import VueTelInputVuetify from "vue-tel-input-vuetify/lib"

Vue.use(VueTelInputVuetify, {
	vuetify,
})

const VueCookie = require("vue-cookies")
Vue.use(VueCookie)

Vue.use(DatetimePicker)
Vue.config.productionTip = false

window.apimta = axios.create({
	baseURL: api + "/api",
})

new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount("#app")
