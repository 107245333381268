<template>
	<div class="home w-100">
		<v-container class="main-container w-100 py-10">
			Nothing to see here.
		</v-container>
	</div>
</template>

<script>
export default {
	name: "HomeView",
}
</script>
