<template>
	<div class="mta-wrap pb-12">
		<v-container class="main-container flex-center flex-column" v-if="event">
			<div class="invitation-wrap w-100 d-flex justify-space-between align-center flex-column">
				<a href="https://mtabraham.com" target="_blank">
					<img src="@/assets/logo.svg" alt="" class="my-12 top-logo" style="width: 110px" />
				</a>
				<h1 class="w-100 text-center main-title font-weight-light text-uppercase mt-3">
					You have been invited to
				</h1>
				<h1 class="w-100 font-weight-light mt-3 gold-text">
					{{ event.name }}
				</h1>
				<h4 class="w-100 text-center font-weight-light mb-4">
					{{ formatDatetime(event.datetime) }}
				</h4>
				<div class="w-100 flex-center flex-column mb-10">
					<div class="w-100 flex-center flex-column" v-if="!success && !alreadySuccess">
						<v-text-field color="#937e6f" class="w-100 mta-inputs mb-4 mb-md-5 mt-12"
							v-model="rsvp.full_name" dark filled hide-details label="Full Name"></v-text-field>
						<v-text-field color="#937e6f" type="email" :rules="emailRules" v-model="rsvp.email"
							class="w-100 mta-inputs mb-4 mb-md-5" dark filled hide-details
							label="E-Mail"></v-text-field>
						<vue-tel-input-vuetify v-model="rsvp.phone" required full-width class="w-100 mta-inputs mb-4"
							filled dark color="#937e6f" :preferredCountries="['IL', 'BA']" hide-details
							label="Phone"></vue-tel-input-vuetify>
						<v-select color="#937e6f" class="w-100" dark filled hide-details return-object
							label="My Response" :items="options" item-text="text" v-model="rsvp.status"></v-select>
						<v-btn class="w-100 mt-6" large color="#937e6f10" depressed tile @click="submitRsvp()">
							<span class="gold-text"> Submit </span>
						</v-btn>

						<v-progress-linear indeterminate color="#937e6f15" v-if="!rsvp.full_name || !rsvp.email"
							class="w-100"></v-progress-linear>
						<v-progress-linear color="#937e6f" v-else class="w-100"></v-progress-linear>
					</div>

					<v-alert class="w-100 mt-5" color="success" text v-if="success">
						RSVP Successfully Submitted. Thank you!
					</v-alert>

					<v-alert class="w-100 mt-5" color="success" text v-if="alreadySuccess">
						You have already submitted your response.
					</v-alert>

					<v-alert class="w-100 text-left mt-5" dense text color="error" v-if="rsvpError">
						<span class="w-100 text-left">
							{{ rsvpError }}
						</span>
					</v-alert>
				</div>

				<v-divider dark width="80px" class="my-5"></v-divider>

				<h4 class="w-100 text-center font-weight-light gold-text mt-10">
					HOSTED BY M.T. ABRAHAM GROUP
				</h4>
			</div>
		</v-container>
	</div>
</template>
<script>
export default {
	name: "MtaInvitation",
	props: ["event"],
	data: () => ({
		rsvpError: null,
		sending: false,
		alreadySuccess: false,
		success: false,
		rsvp: {
			event_id: null,
			full_name: null,
			email: null,
			phone: null,
			status: {
				text: "Yes, I will be attending this event",
				value: 1,
			},
		},
		options: [
			{
				text: "Yes, I will be attending this event",
				value: 1,
			},
			{
				text: "No, I will not be attending this event",
				value: 0,
			},
		],
		emailRules: [
			(v) =>
				!v ||
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
				"Please enter a valid e-mail",
		],
	}),
	mounted() {
		this.rsvp.event_id = this.event.id
	},
	methods: {
		formatDatetime(datetime) {
			const date = new Date(datetime)
			return (
				date.toLocaleDateString("en-US", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
				}) +
				" at " +
				date.toLocaleTimeString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				})
			)
		},
		submitRsvp() {
			if(
				!this.rsvp.full_name ||
				!this.rsvp.email ||
				!this.rsvp.status ||
				!this.rsvp.phone
			) {
				this.rsvpError = "Please fill out all fields."

				return
			}

			this.sending = true

			apimta
				.post("/invitations/create", this.rsvp)
				.then((response) => {
					if(response.status === 201) {
						this.sending = false
						this.alreadySuccess = false
						this.rsvpError = null
						this.success = true
					} else if(response.status === 202) {
						this.sending = false
						this.success = false
						this.rsvpError = null
						this.alreadySuccess = true
					}
				})
				.catch(() => {
					this.sending = false
					this.success = false
					this.rsvpError =
						"Something went wrong submitting your response. Please try again later."
				})
		},
	},
}
</script>
<style lang="scss" scoped>
.mta-wrap {
	min-height: 100vh;
	background-color: #1d1d1d;
	color: rgba(255, 255, 255, 0.9);
	font-weight: 400;

	* {
		font-family: "Work Sans", sans-serif !important;
	}

	padding: 0 25px;
}

.gold-text {
	color: #937e6f;
}

.invitation-wrap {
	max-width: 600px;
}

.mta-inputs {
	display: flex;
	max-height: 80px !important;
}

.top-logo {
	transition: all 0.5s ease;

	&:hover {
		opacity: 0.5;
	}
}

.main-title {
	font-size: 40px;
	line-height: 40px;
}

@media (max-width: 768px) {
	.main-title {
		font-size: 25px;
		line-height: 25px;
	}
}
</style>
