<template>
	<div id="app">
		<v-app>
			<v-main>
				<router-view />
			</v-main>
		</v-app>
	</div>
</template>

<script>
export default {
	name: "App",
	data: () => ({}),
}
</script>

<style lang="scss">
body {
	padding: 0;
	margin: 0;
}
.theme--dark.v-data-table
	> .v-data-table__wrapper
	> table
	> tbody
	> tr:hover:not(.v-data-table__expanded__content):not(
		.v-data-table__empty-wrapper
	) {
	background: #2c2c2c !important;
	cursor: pointer;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	min-height: 100vh;
	position: relative;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
	background: #c7a45712 !important;
}
.main-container {
	max-width: 1400px;
	padding: 0 30px;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-menu {
	.v-input {
		min-width: 300px;
		width: 100% !important;
	}
}
.w-100 {
	width: 100% !important;
}
.flex-column {
	flex-direction: column;
}
.pointer {
	cursor: pointer;
}
@media (max-width: 768px) {
	.main-container {
		padding: 0 20px;
	}
}
.v-text-field__details {
	display: none !important;
}
.country-code {
	.v-input__slot {
		margin: 0 !important;
	}
}
</style>
