<template>
	<div>
		<v-container class="main-container py-10" v-if="eventError">
			<v-alert text class="w-100 text-left" color="warning">
				{{ eventError }}
			</v-alert>
		</v-container>

		<div class="w-100" v-if="event">
			<chamber-invitation :event="event" v-if="event.host == 'isbih'"></chamber-invitation>
			<mta-invitation :event="event" v-if="event.host == 'mta'"></mta-invitation>
		</div>
	</div>
</template>
<script>
import ChamberInvitation from "../components/ChamberInvitation.vue"
import MtaInvitation from "../components/MtaInvitation.vue"

export default {
	name: "EventView",
	data: () => ({
		event: null,
		eventError: null,
	}),
	components: {ChamberInvitation, MtaInvitation},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			apimta
				.get(`/events/id/${this.$route.params.id}`)
				.then((response) => {
					if(response.status === 200 && response.data) {
						this.event = response.data
					} else {
						this.eventError = `Event you are looking for could not be found.`
					}
				})
				.catch((err) => {
					this.eventError = `Something went wrong reaching the event. ${err}`
				})
		},
	},
}
</script>
<style lang="scss">
.invitation-wrap {
	min-height: 70vh;
}
</style>
